.app-bar{
    width: 100%;
    min-height: 8vh;
    background-color: #00675b ;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    padding: 16px;
    /*display: flex;*/
    justify-content: left;
    align-items:center;
  
}

.app-bar > span{
    vertical-align:-webkit-baseline-middle;
}

.info-line{
    width: 100%;
    height: 5vh;
    background-color:#009688;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    padding: 16px;
    display: flex;
    justify-content: left;
    align-items:center;
}


.label{
    margin: 10px;
}

.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    
}

.card-info-board{
    width: 30%;
    margin: 10px;
    padding: 5px;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background-color: #dcf8f5;
    border-radius: 5px;
    height: 10vh;
    text-align: center;
}

.card-info-board .title{
    text-transform: uppercase;
    font-size: 10px;
    color: #009688;
    font-weight: bold;
   
}
.card-info-board .info{
    margin-top:5px;
    text-transform: uppercase;
    font-size: 20px;
}


.message-alert{
    width: 90%;
    font-size: 11px;
    color: #ff0000;
    text-transform: uppercase;
    font-weight: bold;
    /* background-color: #ffdada;
    padding: 10px;
    border-radius: 5px; */
 
}

.back-home{
    cursor: pointer;
}