.splash-screen {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .splash-screen .splash{
    max-width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .splash-screen .loading-dot {
    font-size: 100px;
    margin-top: -80px;
    position: relative;
    animation: ease-in-out infinite alternate;
    animation-name: run;
    animation-duration: 1.2s;
  }
  
  @keyframes run {
    0% {
      left: -90px;
      color: #fbd208;
    }
    50% {
      color: #104c88;
    }
    100% {
      left: 90px;
      color: #538fc6;
    }
  }